<template>
  <div class="veterans">
      <van-form @submit="onSubmit" validateTrigger="onSubmit"> 
        <van-field v-model="info.account" name="account" label="登录账号" placeholder="请输入登录账号" :rules="[{ required: true}]">
            <template #left-icon>
               <van-icon name="user-o" />
            </template>
        </van-field> 
        <van-field
            readonly
            clickable
            label="社区"
            :value="info.shequName"
            placeholder="请选择社区"
            @click="showPicker1 = true"
            :rules="[{ required: true}]"
        >
            <template #left-icon>
               <van-icon name="wap-home-o" />
            </template>
        </van-field>
        <van-popup v-model="showPicker1" round position="bottom">
            <van-picker
                show-toolbar
                value-key="deptName"
                :columns="shequList"
                @cancel="showPicker1 = false"
                @confirm="onConfirm1"
            />
        </van-popup>
        <van-field
            readonly
            clickable
            label="角色"
            :value="info.roleName"
            placeholder="请选择角色"
            @click="showPicker2 = true"
            :rules="[{ required: true}]"
        >
            <template #left-icon>
               <van-icon name="wap-home-o" />
            </template>
        </van-field>
        <van-popup v-model="showPicker2" round position="bottom">
            <van-picker
                show-toolbar
                value-key="dictValue"
                :columns="roleList"
                @cancel="showPicker2 = false"
                @confirm="onConfirm2"
            />
        </van-popup>

        <van-field v-model="info.position" name="position" label="职责" placeholder="请输入职责" :rules="[{ required: true}]">
            <template #left-icon>
               <van-icon name="user-o" />
            </template>
        </van-field> 
        <!-- <van-field
            readonly
            clickable
            label="小区"
            :value="info.xiaoquName"
            placeholder="请选择小区"
            @click="showPicker2 = true"
            :rules="[{ required: true}]"
        >
            <template #left-icon>
               <van-icon name="wap-home-o" />
            </template>
        </van-field>
        <van-popup v-model="showPicker2" round position="bottom">
            <van-picker
                show-toolbar
                value-key="deptName"
                :columns="xiaoquList"
                @cancel="showPicker2 = false"
                @confirm="onConfirm2"
            />
        </van-popup> -->
        <van-field v-model="info.realName" name="realName" label="姓名" placeholder="姓名" :rules="[{ required: true}]">
            <template #left-icon>
               <van-icon name="user-o" />
            </template>
        </van-field> 
        <van-field
            name="sex"
            label="性别"
            :rules="[{ required: true, message: '请选择性别' }]"
        >
            <template #left-icon>
               <van-icon name="friends-o" />
            </template>
            <template #input>
            <van-radio-group v-model="info.sex" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
            </van-radio-group>
            </template>
        </van-field>
        <!-- <van-field v-model="info.account" name="account" label="身份证号" placeholder="身份证号" :rules="[{ validator: isCardNo, message: '请输入正确身份证号' }]">
            <template #left-icon>
               <van-icon name="description" />
            </template>
        </van-field>   -->
        <!-- <van-field v-model="info.phone" name="phone" label="手机号" placeholder="手机号" :rules="[{ validator: isMobile, message: '请输入正确手机号' }]"> -->
        <van-field v-model="info.phone" name="phone" label="手机号" placeholder="手机号" :rules="[ { validator: isMobileNotRequired, message: '请输入正确手机号' }]">
            <template #left-icon> 
               <van-icon name="records" />
            </template>
        </van-field>  
        
        <van-field v-model="info.beizhu" disabled name="密码" label="密码" placeholder="默认密码：Szxc@2022" >
            <template #left-icon>
               <van-icon name="phone-o" />
            </template>
        </van-field>

        <div >
            <van-button  block type="info" native-type="submit">提交</van-button>
        </div>
        </van-form>
  </div>
</template>

<script>  
import { levelList } from "@/api/registerApi"
import { bindUser, getRoleList } from "@/api/userApi"
import { isMobileNotRequired, isCardNo } from '@/utils/validate' 
import { getWxConfigs, getOpenid } from '@/api/wxApi'
import {removeToken} from '@/utils/token.js'
import store from '@/store';  
import wx from 'weixin-jsapi'

export default {
  name: 'Register',
  components: {
  }, 
  data() {
    return {
        showPicker1: false,
        showPicker2: false,
        shequList: [],
        xiaoquList: [],
        roleList: [],
        info: {
            openid: this.$store.getters.openId,
            shequId:  '', 
            xiaoquId: '',
            shequName:  '',  
            xiaoquName:  '',  
            realName:  '',
            account:  '',
            phone:  '',
            beizhu: '', 
            sex: '0', 
        },
    }
  },
  // 计算属性
  computed: { 
  },

  // watch
  watch: {
      aaa:{//深度监听，可监听到对象、数组的变化
         handler(val){
            this.aaa = val 
         },
         deep: true
     }
  },
 
  created() {
    // 清除token和openid
    removeToken()
    this.$store.commit('SET_OPEN_ID', '')

    this.getrole()
      this.getLevelList()
      let url = window.location.href.split('#')[0] 
    getWxConfigs({url: url}).then(res => {  
      wx.config({
        debug: false,
        appId: res.data.appId,
        timestamp: "" + res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ['chooseImage', 'previewImage'],
        openTagList: ["wx-open-launch-weapp"]
      })  
      this.appid = res.data.appId
      if(!this.$store.getters.openId) {
        this.hasCode()
      }
     
    })
  },  
  methods: {
      isMobileNotRequired: isMobileNotRequired,
      isCardNo: isCardNo,
      getrole() {
        getRoleList().then(res => {
          this.roleList = res.data
        })
      },

      hasCode() {
        let code = this.GetQueryString('code')
        
        if(code) {
          // 获取openid
          getOpenid({
            code: code
          }).then(res => {
            store.commit('SET_OPEN_ID', res.data)
          })
        } else {
          // window.location.href = url2
          this.getCodeApi()
        }
      },
      getCodeApi() {
        //获取code
        let urlNow = encodeURIComponent(window.location.href);
        // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知

        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          urlNow +
          "&response_type=code&scope=snsapi_base#wechat_redirect";
        window.location.href = url;
      },
      GetQueryString(name){
          var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
          var r = window.location.search.substr(1).match(reg);
          if(r!=null)
          return  unescape(r[2]); 
          return null;
      },

      getLevelList(parentId) {
        levelList({parentId: parentId}).then(res => {
            if (res.code === 200) {
                if (parentId) {
                    this.xiaoquList = res.data
                    this.info.xiaoquId = ''
                    this.info.xiaoquName = ''
                } else {
                    this.shequList = res.data 
                }
            }
        }) 
      },
      onConfirm1(val) { 
        this.info.shequId = val.id
        this.info.shequName = val.deptName
        this.getLevelList(val.id)
        this.showPicker1 = false
      },
      onConfirm2(val) { 
        this.info.roleId = val.dictKey
        this.info.roleName = val.dictValue
        this.showPicker2 = false
      },  
      onSubmit(val){
        bindUser({
            openid: this.$store.getters.openId, 
            account: this.info.account,
            realName: this.info.realName,
            roleId: this.info.roleId,
            streetId: this.info.shequId,
            deptId: this.info.shequId,
            sex:  this.info.sex,
            phone: this.info.phone,
            position: this.info.position,

        }).then(res => {
                // this.$store.dispatch('GetToken').then(() => { 
                      
                // }) 
            this.$toast({
                type: 'success',
                message: '提交成功,等待管理员审核',
                duration: 1000,
                onClose: function() { 
                  wx.closeWindow()
                    // window.location.href = '/home'
                }
            }) 
                 
        }) 
      },  
  }
}
</script>

<style lang='scss'>
</style>
<style lang='scss' scoped>
.veterans{
    background: #f3f4f6;
    h3{
        color: #8d8c8c;
        font-size: 16px;
        font-weight: 300;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
    }
    .joinArmyTime-action,.leaveArmyTime-action{
        position: absolute;
        left: 0%;
        bottom: 0;
        width: 100%;
        z-index: 99;
    }
}
</style>
